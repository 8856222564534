<template>
<div class="">
  <div class="about text-center" style="min-height: 500px;" v-if="!MessageLicence">
    <v-img src="../../assets/admin/wait.png" max-width="68%" min-height="500px" class="mx-auto my-3">
      <div style="position: absolute; bottom: 10px; left: 50%;transform: translate(-50%, -10%);">
      <h1>Please Wait</h1>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
      </div>
      
    </v-img>
    
    <!-- <v-btn @click="EnviarClever">probar</v-btn>
    <v-btn @click="GetDataUser">obtener info user</v-btn>
    <v-btn @click="GetDataSection">obtener info seccion</v-btn>
    <v-btn @click="CustomToken">Probar firebase</v-btn>
    <v-btn @click="loginData">Login firebase</v-btn>
    <v-btn @click="pruebalogin">probar verificacion de bd</v-btn> -->
  </div>
  <div v-else class="license">
    <div style="min-height: 400px; position:relative">
      <p style="position:absolute; top: 17%; left:5%" class="text-h5">Enter the code you created in Lee Lee TE.</p>
      <div class="box-code">
        <v-alert dense outlined type="error" v-if="errorMessage != ''">
                {{ errorMessage }}
              </v-alert>
        <v-row><p class="mx-auto text-h4 pt-3">Class Code:</p></v-row>
        <v-row class="px-5">
          <v-text-field single-line filled outlined v-model="code" @keyup="uppercase"></v-text-field>
        </v-row>
        <div>
          <v-btn x-large depressed :disabled="loadSearchCode" :loading="loadSearchCode" color="primary" @click="SearchCode">
                        Enter
                    </v-btn>
        </div>
      </div>
      <p style="position:absolute; bottom:0; left:5%" class="text-h6">* If you do not have a class code created, you need a active license for access.</p>
        <!-- <v-card class="mx-auto ma-15 pa-10 text-center" max-width="1200" rounded="rounded-xl">
            <div class="text-h3"> NEED A LICENCE FOR ACCESS</div>
        </v-card> -->
         <!-- <p class="text-center">or if you have a code </p>
        <v-card width="700" class="mx-auto pa-5">
            <v-alert dense outlined type="error" v-if="errorMessage != ''">
                {{ errorMessage }}
              </v-alert>
            <v-row>
                <v-col cols="4" class="my-auto text-center">
                    Enter Code: 
                </v-col>
                <v-col cols="4" class="my-auto">
                    <v-text-field single-line filled outlined v-model="code" @keyup="uppercase"></v-text-field>
                </v-col>
                <v-col class="my-2">
                    <v-btn depressed :disabled="loadSearchCode" :loading="loadSearchCode" color="primary" @click="SearchCode">
                        Enter
                    </v-btn>
                </v-col>
            </v-row>
        </v-card> -->

    </div>
  </div>
</div>
  
  
</template>
<script>
import { functions, auth, db } from '../../plugins/firebase'
export default {
  data () {
    return {
      info: null,
      idClever: '',
      access_token: '',
      email: '',
      firstName: '',
      lastName: '',
      MessageLicence: false,
       code: '',
       errorMessage:'',
       loadSearchCode: false,
       TempTokenStudentReassigned: '',
       isDeleted: false
    }
  },
  created () {
    console.log('entro correctamente')
  },
  methods: {
    async EnviarClever () {
      try {
        let codeClever = this.$route.query.code;
        const loginClever = functions.httpsCallable('loginClever') // loginClever    --- loginCleverDemo
        await loginClever({ code: codeClever, url: 'https://claseleelee.com/oauth2' }) //https://claseleelee.com/oauth2     ---    http://localhost:8080/oauth2
          .then((res) => {
            console.log(res) //  https://claseleelee.com/oauth2
            if (res.data.answer) {
              this.access_token = res.data.tokenRes.access_token;
              this.idClever = res.data.idUserClever.data.id;
              this.GetDataUser()
              this.GetDataSection()
            }else{
              console.log('error primero')
              this.$router.push({path: '/errorLog'})
            }
          })
      } catch (error) {
        console.log(error)
        this.$router.push({path: '/errorLog'})
      }
    },
    async loginData() {
      let localStore =this.$store;
      try {
        auth().signOut()
        localStorage.removeItem('email');
        localStorage.removeItem('uid');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        // let existe = await this.existsUser(this.email);
        // console.log(existe)
        // if (!existe.result) {
        //   console.log('no existe');
        //   return;
        // }
        const VerifyLogin = functions.httpsCallable('VerifyLoginTeacher')
        await VerifyLogin({ access_token: this.access_token, idClever: this.idClever, email: this.email })
          .then((res) => {
            //console.log(res)
            if (Object.prototype.hasOwnProperty.call(res.data, 'error')) 
            {
              console.log('ocurrio un error')
              this.$router.push({path: '/errorLog'})
              return;
            }
            if (Object.prototype.hasOwnProperty.call(res.data, 'token')) {
              if ((!res.data.crear) && (!res.data.user) ) {
                console.log('ocurrio un error')
              this.$router.push({path: '/errorLog'})
              return;
              }
              auth().signInWithCustomToken(res.data.token)
                .then((userCredential) => {
                  //guardamos token para seccion
                  db.collection("TempTokensClever").doc(this.idClever).set({ date: Date.now(), token: this.access_token })
                  //
                  //localStorage.setItem('token2', res.data.token)
                  if (res.data.crear) {
                    //aqui creamos
                    console.log('creamos')
                    userCredential.user.updateEmail(this.email);
                    const createCustomUser = functions.httpsCallable('createCustomUser')
                    createCustomUser({ uid: this.idClever, email: this.email, firstName: this.firstName, lastName: this.lastName }).then((respues) => {
                      console.log(respues)
                      console.log('termino de crear');
                      this.LoginStoreClever(true);
                    })
                  } else {
                    //logueamos
                    this.LoginStoreClever(false);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
            }
          })
      } catch (error) {
        console.log(error)
        this.$router.push({path: '/errorLog'})
      }
    },
    LoginStoreClever (nuevo) {
      this.$store.dispatch('loginClever', nuevo).then(() => {
        // TODO - verificar los custom claims
        this.$router.push({path: '/login'})
      }).catch(error => {
        console.log(error);
        this.errorMessage = error;
      });
    },
    pruebalogin () {
      console.log('asd')
      const usersRef = db.collection('SchoolTeacher').doc(this.idClever)

      usersRef.get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            usersRef.onSnapshot((doc) => {
              // en este caso logeamos
              console.log('loguear')
            });
          } else {
            // en este caso creamos 
            console.log('crear')
            
          }
      });
    },
    GetDataUser () {
      const url = 'https://api.clever.com/v3.0/users/' + this.idClever;
      const options = {
        method: 'GET',
        headers: {Accept: 'application/json', Authorization: 'Bearer ' + this.access_token}
      };

      fetch(url, options)
        .then(res => res.json())
        .then(json => {
          let role = Object.keys(json.data.roles);
          console.log(json)
          if(role[0] == "student"){
            this.firstName = json.data.name.first;
            this.lastName = json.data.name.last;
            this.loginDataStudent();
          }else{
            this.email = json.data.email;
            this.firstName = json.data.name.first;
            this.lastName = json.data.name.last;
            this.loginData();
          }
        })
        .catch(err => console.error('error:' + err));
    },
    GetDataSection () {
      // const url = 'https://api.clever.com/v3.0/users/'+this.idClever+'/mystudents';
      // const options = {
      //   method: 'GET',
      //   headers: {Accept: 'application/json', Authorization: 'Bearer '+this.access_token}
      // };

      // fetch(url, options)
      //   .then(res => res.json())
      //   .then(json => console.log(json))
      //   .catch(err => console.error('error:' + err));
      const url = 'https://api.clever.com/v3.0/users/'+this.idClever+'/sections';
      const options = {
        method: 'GET',
        headers: {Accept: 'application/json', Authorization: 'Bearer '+this.access_token}
      };

      fetch(url, options)
        .then(res => res.json())
        .then(json => console.log(json))
        .catch(err => console.error('error:' + err));
      
      // const url2 = 'https://api.clever.com//v3.0/users/60b6e06801144102ed2254ee';
      // const options2 = {method: 'GET', headers: {Accept: 'application/json', Authorization: 'Bearer '+this.access_token}};

      // fetch(url2, options2)
      //   .then(res => res.json())
      //   .then(json => console.log(json))
      //   .catch(err => console.error('error:' + err));
      // const url2 = 'https://api.clever.com/v3.0/users';
      // const options2 = {
      //   method: 'GET',
      //   headers: {Accept: 'application/json', Authorization: 'Bearer '+this.access_token}
      // };

      // fetch(url2, options2)
      //   .then(res => res.json())
      //   .then(json => console.log(json))
      //   .catch(err => console.error('error:' + err));
    },
    CustomToken () {
      auth().signInWithCustomToken('ilbacac11b7d3699a900ed665ee1b999dd0b33ea')
      .then((userCredential) => {
        console.log(userCredential)
        // ...
      })
      .catch((error) => {
        console.log(error)
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
      });
    },
    async existsUser (email) {
      let answer = { result: false, create: false }
      let signInMethods = await auth().fetchSignInMethodsForEmail(email);
      if (signInMethods.length == 0) {
        let docVerify = await db.collection("PreUsersClever").doc(email).get();
        if (!docVerify.exists) {
          console.log('No existe!');
          //answer.result = false;
        } else {
          console.log('si existe');
          answer.result = true;
          answer.create = true;
        }  
      } else {
        console.log(signInMethods)
        console.log('si existe')
        answer.result = true;
        //answer.create = true;
      }
      return answer;
    },
    async loginDataStudent() {
      try {
        auth().signOut()
        localStorage.removeItem('email');
        localStorage.removeItem('uid');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        const VerifyLogin = functions.httpsCallable('VerifyLoginStudents')
        await VerifyLogin({ access_token: this.access_token, idClever: this.idClever })
          .then((res) => {
            console.log(res)
            if (!res.data.exist) 
            {
              // console.log('ocurrio un error')
              // this.$router.push({path: '/errorLog'})
              this.MessageLicence = true;
              return;
            }
            if (res.data.isDeleted) {
              //this.TempTokenStudentReassigned =res.data.token;
              this.isDeleted = true;
              this.MessageLicence = true;
              return;
            }
            if (Object.prototype.hasOwnProperty.call(res.data, 'token')) {
              auth().signInWithCustomToken(res.data.token)
                .then((userCredential) => {
                  db.collection("TempTokensClever").doc(this.idClever).set({ date: Date.now(), token: res.data.token })
                  if (res.data.crear) {
                    if (res.data.licences<1) {
                      console.log('no tiene licencia')
                      this.$router.push({path: '/errorLog'})
                      return;
                    }
                    //aqui creamos
                    console.log('creamos')
                    //userCredential.user.updateEmail(this.email);
                    const createCustomUser = functions.httpsCallable('createCustomStudent')
                    createCustomUser({ uid: this.idClever, firstName: this.firstName, lastName: this.lastName }).then((respues) => {
                      console.log(respues)
                      console.log('termino de crear');
                      this.LoginStoreClever(true);
                    })
                  } else {
                    //logueamos
                    this.LoginStoreClever(false);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
            }
          })
      } catch (error) {
        
      }
    },
    SearchCode() {
      this.loadSearchCode= true;
     
      const CreateStudetnCode = functions.httpsCallable('CreateStudetnCode')
      CreateStudetnCode({ uid: this.idClever, firstName: this.firstName, lastName: this.lastName, code: this.code, isDeleted:this.isDeleted }).then((respues) => {
        console.log(respues)
        //console.log('termino de crear');
        if (respues.data.answer) {
          
          auth().signInWithCustomToken(respues.data.token).then((userCredential) => {
            db.collection("TempTokensClever").doc(this.idClever).set({ date: Date.now(), token: respues.data.token }).then(()=>{
              this.LoginStoreClever(true);
            });
            //console.log(userCredential)
          })
          
        }else{
          this.errorMessage ='error code'
          setTimeout(() => this.errorMessage = '', 4000);
          this.loadSearchCode = false;
        }
       //this.LoginStoreClever(true);
      })
      // let find = await db.collection('CodeAccessStudents').doc(this.code).get(); 
      // console.log(find.exists)
      // if (find.exists) {
      //   console.log('entro codigo')
      // }
    },
    uppercase() {
      this.code = this.code.toUpperCase();
    },
  },
  mounted () {
    this.EnviarClever();
  }
}
</script>

<style scoped>
  .license{
    background-image: url('../../assets/admin/code.png');
   
    background-position: center;
    background-repeat: no-repeat, repeat-y;
    background-size: cover;
    min-height: 450px;
  }
  .box-code{
    background-color: white;
    border-radius: 25px;
    border: 2px solid #73AD21;
    padding: 10px;
    text-align: center;
    max-width: 650px;
    width: 550px;
    position: absolute;
    bottom: 70px; 
   left: 5%;
  }
</style>